import React from "react"

import Layout from "./../layouts/Layout/Layout"
import TwoColumnSection from "../sections/TwoColumnSection/TwoColumnSection"
import FeaturesList from "../sections/FeaturesList/FeaturesList"
import HeroSubpage from "../sections/HeroSubpage/HeroSubpage"

import Background from "../assets/images/hero_parent.jpg"

import IconScholarship from "../assets/svg/icon_scholarship.svg"
import IconChart from "../assets/svg/icon_chart.svg"
import IconID from "../assets/svg/icon_identifiacationCard.svg"
import IconCompetencies from "../assets/svg/icon_competencies.svg"
import IconGroup from "../assets/svg/icon_group.svg"
import IconFactory from "../assets/svg/icon_factory.svg"
import IconTravelCard from "../assets/svg/icon_travelCard.svg"
import Faq from "../sections/Faq/Faq"

const ParentPage = () => (
  <Layout>
    <HeroSubpage title="Dla rodzica" backgroundImage={Background} />
    <TwoColumnSection
      heading="Szansa na naukę i pewną zawodową przyszłość"
      article={
        "<h3>Masz zdolne dziecko i chcesz, by mogło w pełni rozwinąć skrzydła w obszarze edukacji i przyszłej pracy?</h3>" +
        "<p>Program Alkantara to inicjatywa, która łączy naukę z zakresu szeroko pojętego biznesu z możliwością korzystania z finansowego wsparcia. Uczestnicy Akademii Alkantara biorą udział w angażujących zajęciach z ekspertami, korzystają ze wsparcia dedykowanego mentora i mogą podjąć płatna praktyka zawodowa w jednej z naszych partnerskich firm.</p>"
      }
      buttonText="Więcej o Akademii"
      buttonLink="/akademia"
      equalSections
    />
    <TwoColumnSection
      heading="Do kogo kierujemy ofertę?"
      article={
        "<p>Do udziału w Programie Alkantara zapraszamy zdolne osoby, które chcą studiować na Trójmiejskich uczelniach. Jeśli Twoje dziecko jest teraz w klasie maturalnej, wyróżnia się świetnymi wynikami w nauce i marzy o studiach w Gdyni, Gdańsku lub Sopocie – to doskonały moment, by wziąć udział w rekrutacji 2020/2021.</p>"
      }
      equalSections
    />
    <FeaturesList
      title="Co Twoje dziecko zyska jako uczestnik Programu Alkantara?"
      items={[
        {
          backgroundColor: "#A2A446",
          svg: IconCompetencies,
          text: "praktyczną wiedzę i cenne kompetencje zawodowe",
        },
        {
          backgroundColor: "#A2A446",
          svg: IconCompetencies,
          text: "współpracę z biznesowymi ekspertami",
        },
        {
          backgroundColor: "#A2A446",
          svg: IconChart,
          text: "możliwość poznania mechanizmów biznesowych",
        },
        {
          backgroundColor: "#A2A446",
          svg: IconFactory,
          text: "okazję, by poznać działanie firm od podszewki",
        },
        {
          backgroundColor: "#A2A446",
          svg: IconGroup,
          text: "kompleksowe wsparcie profesjonalnego mentora",
        },
        {
          backgroundColor: "#A2A446",
          svg: IconScholarship,
          text: "możliwość uzyskania stypendium na czas studiów ",
        },
        {
          backgroundColor: "#A2A446",
          svg: IconTravelCard,
          text: "płatna praktyka zawodowa w wybranej firmie partnerskiej",
        },
        {
          backgroundColor: "#A2A446",
          svg: IconID,
          text: "lepszy start na rynku pracy ",
        },
      ]}
    />
    <TwoColumnSection
      heading="Martwisz się o środki na studia w Trójmieście? Zgłoś się do Funduszu Stypendialnego"
      article={
        "<p>Fundusz Stypendialny Alkantara uruchomiliśmy z myślą o zdolnych osobach, które z różnych przyczyn nie mogą podjąć wymarzonych studiów w Gdańsku, Gdyni czy w Sopocie. Stypendium pomaga zorganizować życie w nowym miejscu i stworzyć odpowiednie warunki do nauki i rozwoju. </p>"
      }
      buttonText="Więcej o Funduszu"
      buttonLink="/fundusz"
      equalSections
      noBorder
    />
    <Faq
      lastChild
      faqItems={[
        {
          question: "Czym jest Program Alkantara?",
          answer:
            "<p>Program Alkantara to zajęcia biznesowe oraz fundusz stypendialny stworzony dla zdolnych studentów z województwa pomorskiego i nie tylko. Obejmuje 4 obszary: edukację, mentoring, praktykę zawodową Jego celem jest rozwój kompetencji biznesowych młodych ludzi i zapewnienie im lepszego startu na rynku pracy.</p>",
        },
        {
          question: "Kto może wziąć udział w Akademii Alkantara?",
          answer:
            "<p>Do Programu Alkantara mogą zgłaszać się zdolni uczniowie klas maturalnych, którzy chcą studiować na trójmiejskich uczelniach. Żeby dowiedzieć się więcej, należy skontaktować się z wychowawcą lub doradcą zawodowym w szkole. To osoby, które pomogą wypełnić wszystkie dokumenty potrzebne w procesie rekrutacji. Nabór na rok akademicki 2021/2022 trwa do 12 grudnia 2020.</p>",
        },
        {
          question: "Kto może skorzystać z Funduszu Stypendialnego?",
          answer:
            "<p>Udział w Funduszu Stypendialnym mogą zgłaszać osoby, które z różnych przyczyn nie mogą podjąć wymarzonych studiów w Trójmieście. Każde zgłoszenie rozpatrujemy indywidualnie. Wysokość stypendium ustalamy tak, by umożliwić naszym uczestnikom utrzymanie w mieście, w którym chcą studiować. Bierzemy pod uwagę indywidualne potrzeby uczestnika, uwzględniając koszty utrzymania oraz zapewnienie niezbędnych warunków do nauki i rozwoju.</p>",
        },
        {
          question:
            "Czym się różni Akademia Alkantara od Funduszu Stypendialnego?",
          answer:
            "<p>Akademia Alkantara to program angażujących zajęć i warsztatów biznesowych prowadzonych przez doświadczonych praktyków (przedsiębiorców, managerów i szkoleniowców związanych z biznesem). Program Akademii Alkantara realizujemy w trzech obszarach: edukacji, mentoringu i praktyce zawodowej.</p>" +
            "<p>Z kolei Fundusz Stypendialny to druga część Programu Alkantara. Jego celem jest wyrównywanie szans młodych i zdolnych ludzi przez pomoc w finansowaniu potrzeb związanych ze studiami w Gdańsku, Gdyni czy w Sopocie. Wysokość stypendium ustalamy tak, by umożliwić naszym uczestnikom utrzymanie w mieście, w którym chcą studiować. Zawsze bierzemy pod uwagę indywidualne potrzeby uczestnika, uwzględniając koszty utrzymania oraz zapewnienie niezbędnych warunków do nauki i rozwoju.</p>" +
            "<p><strong>Można być wyłącznie uczestnikiem Akademii Alkantara lub korzystać zarówno z możliwości Akademii, jak i Funduszu Stypendialnego.</strong></p>",
        },
        {
          question:
            "Czy zajęcia w Akademii Alkantara mogą kolidować z zajęciami na uczelni?",
          answer:
            "<p>Akademia Alkantara jest programem komplementarnym do edukacji na uczelniach wyższych czyli z zasady ma uzupełniać zajęcia akademickie. Zajęcia i spotkania z mentorami ustalamy zawsze w porozumieniu z naszymi uczestnikami, by wszyscy mogli swobodnie brać w nich udział.</p>",
        },
        {
          question:
            "Co się stanie, jeśli uczestnik zrezygnuje z udziału w Akademii Alkantara?",
          answer:
            "<p>Udział w Programie Alkantara jest dobrowolny i nie pociąga żadnych zobowiązań wobec organizatorów. Jeśli uczestnik zrezygnuje z zajęć w Akademii Alkantara, nie ponosi żadnej kary. Jeśli korzysta z Funduszu Stypendialnego, nie musi oddawać pobranych wcześniej pieniędzy. Nie jest też zobowiązany do podjęcia praktyk zawodowych w partnerskich firmach Programu Alkantara.</p>",
        },
        {
          question:
            "Jakie są zobowiązania uczestnika w związku z udziałem w Programie Alkantara?",
          answer:
            "<p>Udział w Programie Alkantara jest dobrowolny i nie pociąga żadnych zobowiązań wobec organizatorów. Zakładamy, że każdy uczestnik dołącza do naszej inicjatywy, żeby korzystać ze wsparcia mentorów, rozwijać się zawodowo i realnie zwiększyć swoje szanse na rynku pracy.</p>",
        },
      ]}
    />
  </Layout>
)

export default ParentPage
